import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { Icon } from '@iconify/react';
import { API_BASE_URL } from '../../config/serverApiConfig';

const Login = () => {
	const [data, setData] = useState({ user: "", password: "" });
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};


	// useEffect(() => {
  //   localStorage.removeItem("token1");
  // }, []);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
  // console.log(data.user);
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setError("");
			const url = API_BASE_URL+"/sbuserlogin";
			const { data: res } = await axios.post(url, data);
			if (res.user_login) {
				window.localStorage.setItem("token", res.user_login);
				window.localStorage.setItem('user_data', JSON.stringify(res.user_data[0]));
				window.localStorage.setItem("adminid", res.user_data[0].adminid);
				window.localStorage.setItem("admin_type", res.user_data[0].admin_type);
				window.localStorage.setItem("adminemail",res.user_data[0].adminemail);
				window.location = "/partners";
			} else { 
				setError(res.Message);
			}
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};

	return (
		<>
			<div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
				<div className="admin-login-page">
					<div className="card">
						<h4 className="card-header text-uppercase">Admin Login</h4>
						<div className="card-body">
							<form onSubmit={handleSubmit}>
								<div className="form-floating mb-3">
									<input
                    id="emailAddress"
                    className="form-control"
                    type="email"
                    name="user"
                    onChange={handleChange}
                    value={data.user}
                    required
                    placeholder="Email Address"
                  />
                  <label htmlFor="emailAddress">Email Address</label>
                </div>
                <div className="input-group mb-3">
                  <div className="form-floating flex-fill">
                    <input
                      id="password"
                      className="form-control"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      value={data.password}
                      required
                      placeholder="Password"
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                  >
                    <Icon icon={showPassword ? "oi:eye" : "oi:eye"} />
                  </button>
                </div>
                <div>
                  {error && <div className={styles.error_msg}>{error}</div>}
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
